'use client'

import { RiErrorWarningLine } from '@remixicon/react'
import { Skeleton } from 'antd'
import Image from 'next/image'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import GeneratedButton from '@/components/Chatbot/ToolsGenerated/GeneratedButton'
import GeneratedForm from '@/components/Chatbot/ToolsGenerated/GeneratedForm'
import GeneratedRadioGroup from '@/components/Chatbot/ToolsGenerated/GeneratedRadioGroup'

import { cn } from '@/utils/clsx'

import SuggestedItems from '../../Cart/SuggestedItems'
import GeneratedCheckboxGroup from '../../ToolsGenerated/GeneratedCheckboxGroup'
import chatbotAvatar from '../../../../../public/chatbotAvatar.png'

import { Agent } from '@/types'
import { IMessage } from '@/types/chatbot'

interface AnswerProps {
  questionInfo: IMessage
  getAnswer?: (
    domain: boolean,
    question: string,
    agent?: string
  ) => Promise<void>
  isLastAnswer?: boolean
  agents?: Agent[]
  className?: string
}

const Answer: React.FC<AnswerProps> = ({
  questionInfo,
  getAnswer,
  isLastAnswer,
  agents,
  className,
}) => {
  const {
    role,
    type,
    message,
    timestamp,
    domain,
    agent,
    isCommand,
    button,
    form,
    radio_group,
    checkbox_group,
    audio,
  } = questionInfo
  const [avatar, setAvatar] = useState<string | undefined>(undefined)
  const { t } = useTranslation()

  const formatQuestion = (question: string) => {
    return (question.charAt(0).toUpperCase() + question.slice(1))
      .split('\n')
      .map((line, index) => {
        return line !== '' ? <p key={index}>{line}</p> : <br key={index} />
      })
  }

  const formattedQuestion = useMemo(() => formatQuestion(message), [message])

  useEffect(() => {
    if (agents !== undefined && agent !== undefined) {
      const a = agents?.find((a) => a.id === agent)?.avatar
      setAvatar(a)
    }
  }, [agents, agent])

  return (
    <div className={cn('px-4 py-2', className)}>
      {role === 'user' && message && (
        <section className='bubble-slide-left relative rounded-xl rounded-br-none shadow-md'>
          <div className='rounded-xl rounded-br-none bg-primary px-4 py-2 text-left dark:bg-dark-primary'>
            <div className='break-words text-left text-sm text-on-primary dark:text-dark-on-primary'>
              {domain ? formattedQuestion : t('use-external-data')}
            </div>
            <p className='text-[10px] font-light text-on-primary/70 dark:text-dark-on-primary/70'>
              {timestamp}
            </p>
          </div>
        </section>
      )}

      {role === 'assistant' && !isCommand && (
        <section className='bubble-slide-right relative mb-3 rounded-xl rounded-bl-none shadow-md'>
          <div className='rounded-xl rounded-bl-none bg-surface px-4 pb-6 pt-2 text-on-surface dark:bg-dark-surface dark:text-dark-on-surface'>
            <div className='mb-14 mt-1 flex flex-col gap-4'>
              {!domain && (
                <div className='flex flex-col gap-2'>
                  <div className='flex items-center justify-center gap-1.5'>
                    <RiErrorWarningLine className='size-5' />
                    <div className='flex items-center justify-center text-xs font-bold'>
                      {t('external-data-header')}
                    </div>
                  </div>
                  <hr />
                </div>
              )}

              <div className='markdown-answer break-words text-left text-sm'>
                {type === 'text' ? (
                  <SuggestedItems message={message} />
                ) : type === 'image_url' && message ? (
                  <Image src={message} alt='Image' width={500} height={500} />
                ) : (
                  t('no-answer')
                )}
                {audio && (
                  <audio
                    src={`data:audio/wav;base64,${audio}`}
                    controls
                    className='mt-4'
                  />
                )}
              </div>
            </div>
          </div>

          {agents && agent ? (
            <Image
              src={avatar ? `data:image/png;base64, ${avatar}` : chatbotAvatar}
              alt='Chatbot avatar'
              className='absolute bottom-[-12px] left-4 size-16 rounded-md shadow-md'
              width={avatar ? 16 : undefined}
              height={avatar ? 16 : undefined}
            />
          ) : (
            <Skeleton.Image
              active={true}
              className='absolute bottom-[-12px] left-4 size-16 rounded-md p-[3px]'
            />
          )}

          {/* <ActionButtons
            question={question}
            questionInfo={questionInfo}
            getAnswer={getAnswer}
            onReferencesClick={onReferencesClick}
            updateQuestion={updateQuestion}
          /> */}
        </section>
      )}
      {isLastAnswer && getAnswer && (
        <div className='mt-8 text-left'>
          {button && (
            <GeneratedButton text={button.text} getAnswer={getAnswer} />
          )}
          {form && <GeneratedForm items={form.items} getAnswer={getAnswer} />}
          {radio_group && (
            <GeneratedRadioGroup
              items={radio_group.items}
              getAnswer={getAnswer}
            />
          )}
          {checkbox_group && (
            <GeneratedCheckboxGroup
              items={checkbox_group.items}
              getAnswer={getAnswer}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default Answer
