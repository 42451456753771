'use client'

import { useSearchParams } from 'next/navigation'
import { createContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useDrawerState from '@/hooks/context/useDrawerState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'
import useConversation from '@/hooks/useConversation'
import useSettings from '@/hooks/useSettings'

import QuestionInput from '@/components/Chatbot/QuestionInput'

import { DEFAULT_USE_CONTACT_US_CTA } from '@/constants/env'
import { getAnswer, stopAnswering } from '@/service/Chatbot'
import { cn } from '@/utils/clsx'

import Answers from './Chatbot/Output/Answers'
import ContactUsModal from './Chatbot/Output/ContactUsModal'
import UnderMaintenance from './UnderMaintenance'

import { Swarm } from '@/types'
import { IQuestion } from '@/types/chatbot'

export const IndexPageContext = createContext(false)

interface ChatbotProps {
  mutateConversations?: () => void
  indexPage?: boolean
  hideHeader?: boolean
  adminChatbot?: boolean
  swarm?: Swarm
  isOffline?: boolean
  className?: string
}

const Chatbot: React.FC<ChatbotProps> = ({
  indexPage,
  hideHeader,
  adminChatbot,
  swarm,
  isOffline,
  className,
}) => {
  const { t } = useTranslation()
  const searchParams = useSearchParams()
  const { selectedConversation } = useDrawerState()

  const { user } = useAuth()
  const { error: conversationError } = useConversation(selectedConversation)
  const {
    agents,
    selectedAgent,
    selectedAgentAdmin,
    error: agentsError,
  } = useAgents()
  const currentAgent = adminChatbot ? selectedAgentAdmin : selectedAgent
  const { settings } = useSettings(currentAgent?.id ?? '')

  const [questions, setQuestions] = useState<IQuestion[]>([])
  const [loading, setLoading] = useState(false)
  const [userSettings, setUserSettings] = useState<{
    useGpt4: boolean
    useDrafting: boolean
    useStreaming: boolean
  }>()
  const [fileList, setFileList] = useState<FileList | null>(null)
  const [dragging, setDragging] = useState<boolean>(false)

  useEffect(() => {
    if (settings && !userSettings) {
      setUserSettings({
        useGpt4: settings.model === 'gpt4' || settings.model === 'gpt4o',
        useDrafting: !!settings.useDrafting,
        useStreaming: !!settings.useStreaming,
      })
    }
  }, [settings])

  useEffect(() => {
    if (questions.length === 0 && selectedAgent && t('barman-greeting')) {
      setQuestions([
        {
          question: `{"text": "${t('barman-greeting')}", "items": []}`,
          messages: [
            {
              message: `{"text": "${t('barman-greeting')}", "items": []}`,
              role: 'assistant',
              timestamp: new Date().toISOString(),
              documents: [],
              titles: [],
              drafts: [],
              feedbackScore: 0,
              googleDriveUrls: [],
              isCommand: false,
              domain: true,
              type: 'text',
              agent: selectedAgent?.id,
            },
          ],
        },
      ])
    }
  }, [selectedAgent])

  // when conversation is loaded, map it to questions
  // useEffect(() => {
  //   if (conversation && agents && !conversationError && !agentsError) {
  //     setQuestions(mapConversation(conversation, agents))
  //   }
  // }, [conversation, agents])

  // get answer when all necessary data is loaded
  useEffect(() => {
    if (
      !!userSettings &&
      !!currentAgent &&
      searchParams.get('question') &&
      !loading
    ) {
      getAnswerWrapper(true, searchParams.get('question')!)
    }
  }, [userSettings, currentAgent, searchParams])

  const getAnswerWrapper = async (
    domain: boolean,
    question: string,
    agentId?: string,
    isCommand = false
  ) => {
    await getAnswer(
      domain,
      question,
      agentId ?? currentAgent?.id,
      questions,
      agents,
      setLoading,
      setQuestions,
      t,
      isCommand,
      user?.email,
      userSettings?.useGpt4,
      selectedConversation,
      userSettings?.useStreaming,
      userSettings?.useDrafting,
      adminChatbot,
      swarm
    )
  }

  if (conversationError || agentsError) {
    return <UnderMaintenance onRefreshClick={() => location.reload()} />
  }

  return (
    <IndexPageContext.Provider value={!hideHeader ? !!indexPage : false}>
      <main
        className={cn(
          'relative flex flex-col bg-background dark:bg-dark-background shadow-md',
          {
            rounded: !hideHeader,
            'sm:h-auto': !indexPage,
            '': !adminChatbot,
          }
        )}
        onDragOver={(e) => {
          e.preventDefault()
          setDragging(true)
        }}
        onDragLeave={() => setDragging(false)}
        onDragEnd={() => setDragging(false)}
        onDrop={() => setDragging(false)}
      >
        <div className={className}>
          <Answers
            questions={questions}
            getAnswer={getAnswerWrapper}
            hideHeader={hideHeader}
            adminChatbot={adminChatbot}
            onSelectedDraft={(draft) => {
              const tmpQuestions = [...questions]
              const question = tmpQuestions[tmpQuestions.length - 1]
              const message = question!.messages[question!.messages.length - 1]
              if (message?.message) {
                message.message = draft
              }
              setQuestions([...tmpQuestions])
            }}
            agents={agents}
            setQuestions={setQuestions}
            isOffline={isOffline}
          />
          {!!userSettings && (
            <QuestionInput
              userSettings={userSettings}
              setUserSettings={setUserSettings}
              getAnswer={getAnswerWrapper}
              stopAnswering={() =>
                stopAnswering(userSettings.useStreaming, setLoading)
              }
              loading={loading}
              adminChatbot={adminChatbot}
              settings={settings}
              showSuggestedQuestions={questions.length < 2}
              setFileList={setFileList}
              fileList={fileList}
              dragging={dragging}
              agent={adminChatbot ? selectedAgentAdmin : selectedAgent}
              isChatEmpty={questions.length === 0}
            />
          )}
        </div>
      </main>

      {DEFAULT_USE_CONTACT_US_CTA && (
        <ContactUsModal
          chat={questions.flatMap(({ question, messages }) =>
            messages.map(({ message }) => {
              return { question, message }
            })
          )}
        />
      )}
    </IndexPageContext.Provider>
  )
}

export default Chatbot
