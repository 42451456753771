'use client'

import { jsonrepair } from 'jsonrepair'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useCartState from '@/hooks/context/useCartState'

import { markdown } from '@/utils'

import CartItem from './CartItem'
import UpsellItem from './UpsellItem'

import { Product } from '@/types/cart'

interface SuggestedItemsProps {
  message?: string
  upsell?: boolean
  setUsedUpsell?: (usedUpsell: boolean) => void
}

const SuggestedItems: React.FC<SuggestedItemsProps> = ({
  message,
  upsell,
  setUsedUpsell,
}) => {
  const { cart } = useCartState()
  const { t } = useTranslation()
  const [text, setText] = useState('')
  const [items, setItems] = useState([])

  useEffect(() => {
    if (message) {
      try {
        const json = JSON.parse(
          jsonrepair(message.replace('```json', '').replace('```', ''))
        )
        setText(json.text)
        setItems(json.items)
      } catch (e) {
        setText(t('unknown-error'))
      }
    }
  }, [message])

  return (
    <div className='flex flex-col gap-3'>
      {markdown(text)}
      {items.map((suggestedItem: Product, index) => {
        const itemInCart = cart.find((item) => item.name === suggestedItem.name)
        return upsell ? (
          <UpsellItem
            item={itemInCart ?? suggestedItem}
            key={index}
            setUsedUpsell={setUsedUpsell}
          />
        ) : (
          <CartItem item={itemInCart ?? suggestedItem} key={index} />
        )
      })}
    </div>
  )
}

export default SuggestedItems
