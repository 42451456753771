'use client'

import { Fragment, useContext } from 'react'

import useCartState from '@/hooks/context/useCartState'

import { IndexPageContext } from '@/components/Chatbot'

import { cn } from '@/utils/clsx'

import Answer from './Answer/Answer'
import Cart from '../Cart'

import { Agent } from '@/types'
import { IQuestion } from '@/types/chatbot'

interface AnswersProps {
  questions: IQuestion[]
  getAnswer: (
    domain: boolean,
    question: string,
    agent?: string
  ) => Promise<void>
  hideHeader?: boolean
  adminChatbot?: boolean
  onSelectedDraft: (draft: string) => void
  agents?: Agent[]
  setQuestions: (questions: IQuestion[]) => void
  isOffline?: boolean
}

const Answers: React.FC<AnswersProps> = ({
  questions,
  getAnswer,
  hideHeader,
  adminChatbot,
  agents,
  isOffline,
}) => {
  const { isCartOpen } = useCartState()
  const isIndexPage = useContext(IndexPageContext)

  return (
    <>
      <div
        id='chat-container'
        className={cn(
          'hide-scrollbar flex w-full flex-col overflow-hidden overflow-y-auto pb-20',
          !adminChatbot ? 'h-screen' : 'h-full',
          !hideHeader &&
            (isIndexPage
              ? 'items-center pt-12'
              : 'pt-16 sm:h-[40rem] sm:max-h-[80vh]')
        )}
      >
        {isCartOpen && <Cart questions={questions} isOffline={isOffline} />}

        {questions.map(({ messages }, index1) =>
          messages.map((msg, index2) => (
            <Fragment key={`${index1}-${index2}`}>
              {msg.message !== '' && msg.message !== 'NO ANSWER' && (
                <Answer
                  questionInfo={msg}
                  getAnswer={getAnswer}
                  isLastAnswer={
                    index1 === questions.length - 1 &&
                    index2 === messages.length - 1
                  }
                  agents={agents}
                  className={isIndexPage ? 'w-full sm:max-w-screen-md' : ''}
                />
              )}
            </Fragment>
          ))
        )}
        <div id='last-answer' className='opacity-0'>
          a
        </div>
      </div>
    </>
  )
}

export default Answers
