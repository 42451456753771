'use client'

import { RiCheckLine, RiCloseLine, RiWalkLine } from '@remixicon/react'
import { Alert, Button, Divider, Drawer, message, Tooltip } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs'
import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader, PacmanLoader } from 'react-spinners'

import useCartState from '@/hooks/context/useCartState'
import useThemeState from '@/hooks/context/useThemeState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'

import { colors, datetimeFormat } from '@/branding-config'
import { getAnswerDraft } from '@/service/Chatbot'

import CartItem from './CartItem'
import SuggestedItems from './SuggestedItems'
import chatbotAvatar from '../../../../public/chatbotAvatar.png'

import { IQuestion } from '@/types/chatbot'

interface CartProps {
  questions: IQuestion[]
  isOffline?: boolean
}

const Cart: React.FC<CartProps> = ({ questions, isOffline }) => {
  const { theme } = useThemeState()
  const { cart, isCartOpen, setIsCartOpen, table, clearCart } = useCartState()
  const { t, i18n } = useTranslation()
  const { selectedAgent } = useAgents()
  const { user } = useAuth()
  const [sendingOrder, setSendingOrder] = useState(false)
  const [usedUpsell, setUsedUpsell] = useState(false)
  const [sendingOrderTime, setSendingOrderTime] = useState(15)
  const [additionalOrderText, setAdditionalOrderText] = useState()
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)
  const isLoadingAnswer = useRef(false)

  useEffect(() => {
    if (cart.length === 0 || additionalOrderText || isLoadingAnswer.current)
      return

    isLoadingAnswer.current = true
    const question = `
    I added the following items to my order: ${cart
      .map((item) => `${item.name} (${item.quantity})`)
      .join(', ')}.

    Consider which one of these would be most effective and would make the customer buy more: up-sell, cross-sell, new products.
    Respond with a JSON where you offer me either a Cross-sell option, Up-sell option or New products option.
    Respond with very short text.
    Respond in ${i18n.language} language.
    `

    const tmpQuestion: IQuestion = {
      question: question,
      messages: [
        {
          role: 'user',
          type: 'text',
          domain: true,
          message: question,
          agent: selectedAgent.id,
          drafts: [],
          documents: [],
          titles: [],
          googleDriveUrls: [],
          timestamp: dayjs().format(datetimeFormat),
          isCommand: false,
          feedbackScore: 0,
        },
      ],
    }

    getAnswerDraft(
      true,
      question,
      0,
      [...questions, tmpQuestion],
      selectedAgent.id,
      undefined,
      true,
      false,
      user?.id
    )
      .then((response) => {
        setAdditionalOrderText(response.answer)
        isLoadingAnswer.current = false
      })
      .catch((error) => {
        console.error('Failed to fetch answer draft:', error)
        isLoadingAnswer.current = false
      })
  }, [cart, questions, selectedAgent.id, user?.id])

  const callBartender = async () => {
    try {
      await axios.post('/api/call-bartender', { table })
      message.success(t('bartender-called'))
    } catch (error) {
      console.error(error)
    }
  }

  const sendOrder = async () => {
    try {
      await axios.post('/api/new-order', {
        data: cart,
        table,
        upsell: usedUpsell,
      })
      clearCart()
      message.success(t('order-sent'))
    } catch (error) {
      console.error(error)
      message.error(t('order-failed'))
    }
  }

  const startSendOrderTimer = async () => {
    setSendingOrder(true)
    setSendingOrderTime(15)

    let seconds = 15
    const interval = setInterval(async () => {
      seconds--
      setSendingOrderTime(seconds)

      if (seconds === 0) {
        clearInterval(interval)
        await sendOrder()
        setSendingOrder(false)
      }
    }, 1000)

    setIntervalId(interval)
  }

  const confirmOrder = async () => {
    if (intervalId) {
      clearInterval(intervalId)
    }
    await sendOrder()
    setSendingOrderTime(15)
    setSendingOrder(false)
  }

  const cancelOrder = () => {
    if (intervalId) {
      clearInterval(intervalId)
    }
    setSendingOrder(false)
    setSendingOrderTime(15)
    message.warning(t('order-cancelled'))
  }

  return (
    <Drawer
      title={`${t('table')} ${table}`}
      onClose={() => setIsCartOpen(false)}
      open={isCartOpen}
      getContainer={false}
      className='text-left'
      extra={
        !sendingOrder &&
        cart.length > 0 && (
          <>
            {isOffline ? (
              <Tooltip title={t('offline-info')}>
                <Button type='primary' onClick={startSendOrderTimer} disabled>
                  {t('send-order')}
                </Button>
              </Tooltip>
            ) : (
              <Button
                type='primary'
                onClick={startSendOrderTimer}
                disabled={cart.length === 0}
              >
                {t('send-order')}
              </Button>
            )}
          </>
        )
      }
      afterOpenChange={(open) => setIsCartOpen(open)}
    >
      {sendingOrder ? (
        <div className='flex h-full flex-col items-center justify-center gap-6 text-center text-xl font-bold'>
          <h3>
            {t('sending-order')} {sendingOrderTime}s
          </h3>
          <PacmanLoader color={colors[theme].on.surface} />
          <div className='flex items-center gap-2'>
            <Button
              type='primary'
              onClick={confirmOrder}
              icon={<RiCheckLine className='size-4' />}
            >
              {t('confirm-order')}
            </Button>
            <Button
              onClick={cancelOrder}
              icon={<RiCloseLine className='size-4' />}
            >
              {t('cancel-order')}
            </Button>
          </div>
          <h3 className='text-sm'>{t('sending-order-disclaimer')}</h3>
        </div>
      ) : cart.length === 0 ? (
        <div className='flex h-full flex-col items-center justify-center gap-6 text-center text-xl font-bold'>
          <h3>{t('empty-cart')}</h3>
          <h3>{t('empty-cart-info')}</h3>
          {isOffline ? (
            <Tooltip title={t('offline-info')}>
              <Button
                onClick={callBartender}
                icon={<RiWalkLine className='size-4' />}
                disabled
              >
                {t('call-bartender')}
              </Button>
            </Tooltip>
          ) : (
            <Button
              onClick={callBartender}
              icon={<RiWalkLine className='size-4' />}
            >
              {t('call-bartender')}
            </Button>
          )}
        </div>
      ) : (
        <div className='flex flex-col justify-between gap-2'>
          {isOffline && (
            <Alert message={t('offline-info')} type='warning' showIcon />
          )}
          <div className='flex flex-col gap-2'>
            {cart.map((item, index) => (
              <CartItem item={item} key={index} inCart />
            ))}
          </div>
          <div className='flex flex-col pb-6'>
            <Divider plain className='my-3' />
            <div className='flex items-center justify-between px-6 text-base'>
              <div>{t('order-total')}</div>
              <div className='font-bold'>
                €
                {cart
                  .reduce((acc, item) => acc + item.price * item.quantity, 0)
                  .toFixed(2)}
              </div>
            </div>
          </div>
          {isLoadingAnswer.current === true ? (
            <div className='self-center'>
              <ClipLoader color={colors[theme].on.surface} />
            </div>
          ) : additionalOrderText ? (
            <section className='bubble-slide-right relative mb-3 rounded-xl rounded-bl-none shadow-md'>
              <div className='rounded-xl rounded-bl-none bg-surface px-4 pb-6 pt-2 text-on-surface dark:bg-dark-surface dark:text-dark-on-surface'>
                <div className='mb-14 mt-1 flex flex-col gap-4'>
                  <div className='markdown-answer break-words text-left text-sm'>
                    <SuggestedItems
                      message={additionalOrderText}
                      upsell
                      setUsedUpsell={setUsedUpsell}
                    />
                  </div>
                </div>
              </div>

              <Image
                src={
                  selectedAgent.avatar
                    ? `data:image/png;base64, ${selectedAgent.avatar}`
                    : chatbotAvatar
                }
                alt='Chatbot avatar'
                className='absolute bottom-[-12px] left-4 size-16 rounded-md shadow-md'
                width={selectedAgent.avatar ? 16 : undefined}
                height={selectedAgent.avatar ? 16 : undefined}
              />
            </section>
          ) : null}
        </div>
      )}
    </Drawer>
  )
}

export default Cart
