'use client'

import { RiAddLine, RiInformationLine, RiSubtractLine } from '@remixicon/react'
import { Button, Card, Tooltip } from 'antd'
import { useEffect, useState } from 'react'

import useCartState from '@/hooks/context/useCartState'

import { cn } from '@/utils/clsx'

import { Product } from '@/types/cart'

interface CartItemProps {
  item: Product
  inCart?: boolean
}

const CartItem: React.FC<CartItemProps> = ({ item, inCart }) => {
  const { updateQuantity } = useCartState()
  const [quantity, setQuantity] = useState(0)

  useEffect(() => {
    setQuantity(item.quantity ?? 0)
  }, [item.quantity])

  return (
    <Card type='inner'>
      <div className='flex justify-between gap-4'>
        <div className='flex flex-col justify-between text-left'>
          <div className='text-base'>
            {item.name}
            {item.volume ? ` (${item.volume})` : ''}
          </div>
          {!inCart && (
            <div className='text-sm opacity-70'>
              {item.short_description}{' '}
              <Tooltip title={item.long_description}>
                <span className='inline-block align-text-bottom font-semibold'>
                  <RiInformationLine className='inline size-4' />
                </span>
              </Tooltip>
            </div>
          )}
          <div className='text-lg font-bold'>{item.price.toFixed(2)} €</div>
        </div>
        <div className='flex w-fit flex-col gap-2'>
          <div
            className={cn(
              'flex w-full flex-col justify-between rounded-md border border-on-surface/30 text-center dark:border-dark-on-surface/30 h-fit'
            )}
          >
            <Button
              onClick={() =>
                updateQuantity({ ...item, quantity: quantity + 1 })
              }
              icon={<RiAddLine className='size-5' />}
              size='small'
              type='text'
            />
            <div>{quantity}</div>
            <Button
              onClick={() =>
                updateQuantity({ ...item, quantity: quantity - 1 })
              }
              icon={<RiSubtractLine className='size-5' />}
              size='small'
              type='text'
              disabled={quantity === 0}
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default CartItem
