'use client'

import { RiAddLine, RiCheckLine } from '@remixicon/react'
import { Button, Card } from 'antd'

import useCartState from '@/hooks/context/useCartState'

import { Product } from '@/types/cart'

interface UpsellItemProps {
  item: Product
  setUsedUpsell?: (usedUpsell: boolean) => void
}

const UpsellItem: React.FC<UpsellItemProps> = ({ item, setUsedUpsell }) => {
  const { cart, updateQuantity } = useCartState()

  return (
    <Card type='inner'>
      <div className='flex items-center justify-between gap-4'>
        <div className='flex flex-col justify-between text-left'>
          <div className='text-sm'>
            {item.name}
            {item.volume ? ` (${item.volume})` : ''}
          </div>
          <div className='text-base font-bold'>{item.price.toFixed(2)} €</div>
        </div>
        <Button
          size='large'
          onClick={() => {
            updateQuantity({ ...item, quantity: 1 })
            setUsedUpsell?.(true)
          }}
          icon={
            cart.some(
              (cartItem) =>
                cartItem.name === item.name && cartItem.quantity === 1
            ) ? (
              <RiCheckLine className='size-5' />
            ) : (
              <RiAddLine className='size-5' />
            )
          }
          disabled={cart.some(
            (cartItem) => cartItem.name === item.name && cartItem.quantity === 1
          )}
        />
      </div>
    </Card>
  )
}

export default UpsellItem
