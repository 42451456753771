import { RiQuestionLine } from '@remixicon/react'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'

import useModalsState from '@/hooks/context/useModalsState'

import LanguageSwitch from '@/components/Admin/LanguageSwitch'

import { poweredBy } from '@/branding-config'
import { DEFAULT_USE_CONTACT_US_CTA } from '@/constants/env'

import { Agent } from '@/types'

interface InputFooterProps {
  userSettings: {
    useGpt4: boolean
    useDrafting: boolean
    useStreaming: boolean
  }
  setUserSettings: (userSettings: {
    useGpt4: boolean
    useDrafting: boolean
    useStreaming: boolean
  }) => void
  adminChatbot?: boolean
  agent: Agent
}

const InputFooter: React.FC<InputFooterProps> = ({ adminChatbot }) => {
  const { t } = useTranslation()
  const { toggleModal } = useModalsState()

  return (
    <div className='flex h-6 w-full max-w-screen-md items-center justify-between gap-3 px-1'>
      <div className='whitespace-nowrap text-xs text-on-surface dark:text-dark-on-surface'>
        <span className='opacity-50'>{t('powered-by')}</span>{' '}
        <Link
          href={poweredBy.url}
          target='_blank'
          rel='noreferrer'
          className='font-bold text-on-surface hover:text-on-surface/60 dark:text-dark-on-surface hover:dark:text-dark-on-surface/60'
        >
          {poweredBy.text}
        </Link>
      </div>
      <div className='flex items-center gap-3'>
        <LanguageSwitch />

        {DEFAULT_USE_CONTACT_US_CTA && !adminChatbot && (
          <div
            className='flex cursor-pointer items-center gap-0.5 text-xs text-on-surface/80 hover:text-on-surface/60 dark:text-dark-on-surface/80 hover:dark:text-dark-on-surface/60'
            onClick={() => toggleModal('contactUs')}
          >
            <RiQuestionLine className='size-4' />
            <span className='hidden sm:block'>{t('help')}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default InputFooter
